import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { EyeIcon } from '../../components/icons/EyeIcon.jsx'
import { EditIcon } from '../../components/icons/EditIcon.jsx'
import { DeleteIcon } from '../../components/icons/DeleteIcon.jsx'
import { PreviewReceipt } from '../../components/pos'

import { Link } from 'react-router-dom'

import axios from 'axios'

import { loadSettings } from '../../actions/auth'

import { connect } from 'react-redux'

import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Checkbox,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner,
  Accordion,
  AccordionItem
} from '@nextui-org/react'
const Orders = ({ settings, loadSettings }) => {
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure()

  const order_table_columns = [
    {
      key: 'number',
      label: 'ល.រ'
    },
    {
      key: 'date',
      label: 'ថ្ងៃ/ខែ/ឆ្នាំ'
    },
    {
      key: 'time',
      label: 'វេលា'
    },
    {
      key: 'actions',
      label: 'សកម្មភាព'
    },
    {
      key: 'total_usd',
      label: 'តម្លៃសរុប ($)'
    },
    {
      key: 'total_khr',
      label: 'តម្លៃសរុប (៛)'
    }
  ]

  const getSessions = async () => {
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access')}`,
          Accept: 'application/json'
        }
      }
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/orders/sessions/`,
        config
      )

      setSessions(res.data)
    }
  }

  const [isLoading, setIsLoading] = useState(false)
  const [sessions, setSessions] = useState([])

  const [currentOrder, setCurrentOrder] = useState(null)
  const [size, setSize] = useState('')

  const [onViewOrder, setOnViewOrder] = useState(false)
  const [onDeleteOrder, setOnDeleteOrder] = useState(false)

  const viewReceipt = order => {
    setSize('2xl')
    setOnDeleteOrder(false)
    setOnViewOrder(true)
    setCurrentOrder(order)
    onOpen()
  }

  const openDeleteOrderForm = order => {
    setSize('xs')
    setOnViewOrder(false)
    setOnDeleteOrder(true)
    setCurrentOrder(order)
    onOpen()
  }

  const closeModal = () => {
    setOnDeleteOrder(false)
    setOnViewOrder(false)
    setCurrentOrder(null)
    onClose()
  }

  useEffect(() => {
    getSessions()
  }, [setSessions])

  const handleDeleteOrder = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access')}`,
        Accept: 'application/json'
      }
    }

    const res = await axios.delete(
      `${process.env.REACT_APP_API_URL}/orders/${currentOrder.id}/`,
      config
    )

    getSessions()
    onClose()
  }

  const renderOrderCell = React.useCallback((order, columnKey, orders) => {
    const cellValue = order[columnKey]

    switch (columnKey) {
      case 'number':
        return orders.length - orders.indexOf(order)

      case 'date':
        const date = new Date(order.created_at)

        const formattedDate = date.toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          timeZone: 'Asia/Phnom_Penh'
        })

        return formattedDate

      case 'time':
        const datetime = new Date(order.created_at)

        const formattedTime = datetime.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZone: 'Asia/Phnom_Penh'
        })

        return formattedTime
      case 'total_usd':
        return `${(order.total / order.exchange_rate)
          .toFixed(2)
          .toLocaleString()} $`
      case 'total_khr':
        return `${order.total.toLocaleString()} ៛`

      case 'actions':
        return (
          <div className='relative flex items-center justify-center gap-2'>
            <a onClick={() => viewReceipt(order)}>
              <span className='text-lg cursor-pointer text-default-400 active:opacity-50'>
                <EyeIcon />
              </span>
            </a>
            <Link to={`/orders/${order.id}`}>
              <span className='text-lg cursor-pointer text-default-400 active:opacity-50'>
                <EditIcon />
              </span>
            </Link>
            <a onClick={() => openDeleteOrderForm(order)}>
              <span className='text-lg cursor-pointer text-danger active:opacity-50'>
                <DeleteIcon />
              </span>
            </a>
          </div>
        )

      default:
        return cellValue
    }
  }, [])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        scrollBehavior='inside'
        size='2xl'
      >
        <ModalContent>
          <>
            <ModalHeader className='flex flex-col gap-1'></ModalHeader>
            <ModalBody className='h-[500px]  overflow-y-scroll'>
              {onViewOrder && (
                <div className='flex items-center justify-center w-full'>
                  <PreviewReceipt order={currentOrder} settings={settings} />
                </div>
              )}

              {onDeleteOrder && (
                <p className='w-full h-auto text-center'>Are you sure?</p>
              )}
            </ModalBody>
            <ModalFooter>
              {onViewOrder && (
                <>
                  <button
                    className='px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-700'
                    onClick={() => closeModal()}
                  >
                    បិទ
                  </button>
                </>
              )}

              {onDeleteOrder && (
                <>
                  <button
                    className='px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-700'
                    onClick={() => closeModal()}
                  >
                    ទេ
                  </button>
                  <button
                    className='px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-700'
                    onClick={() => handleDeleteOrder()}
                  >
                    យល់ព្រម
                  </button>
                </>
              )}
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
      <main className='overflow-y-scroll max-h-[750px] h-[750px]'>
        <Accordion
          id='accordion-group'
          selectionMode='multiple'
          variant='splitted'
        >
          {sessions.map(session => {
            const date = new Date(session.date)
            return (
              <AccordionItem
                className='accordion-items'
                key={session.id}
                aria-label={session.date}
                title={
                  <div className='flex w-full h-full text-3xl font-bold justify-evenly'>
                    <span>{`ថ្ងៃទី${date.getDate()} ខែ${
                      date.getMonth() + 1
                    } ឆ្នាំ${date.getFullYear()}`}</span>
                    <span>{`តម្លៃសរុប : ${session.total.toLocaleString()} ៛`}</span>
                  </div>
                }
              >
                <div className='w-full p-4 bg-white rounded-md'>
                  {settings && (
                    <Table
                      isStriped
                      removeWrapper
                      isHeaderSticky
                      color='success'
                      aria-label='Order Table'
                    >
                      <TableHeader columns={order_table_columns}>
                        {column => (
                          <TableColumn
                            className='text-base text-black bg-gray-400'
                            align='center'
                            key={column.key}
                          >
                            {column.label}
                          </TableColumn>
                        )}
                      </TableHeader>
                      <TableBody
                        items={session.orders}
                        emptyContent='គ្មានការបញ្ជាទិញ'
                        isLoading={isLoading}
                        loadingContent={<Spinner label='Loading...' />}
                        classNames={{
                          base: 'max-h-[520px] overflow-y-scroll',
                          table: 'min-h-[400px] overflow-hidden'
                        }}
                      >
                        {item => (
                          <TableRow key={item.key}>
                            {columnKey => (
                              <TableCell className='text-base'>
                                {renderOrderCell(
                                  item,
                                  columnKey,
                                  session.orders
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  )}
                </div>
                <section className=''></section>
              </AccordionItem>
            )
          })}
        </Accordion>
      </main>
    </>
  )
}

const mapStateToProps = state => ({
  settings: state.auth.settings
})

export default connect(mapStateToProps, { loadSettings })(Orders)
