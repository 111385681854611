import React, { useState, useEffect, useRef } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { SearchIcon } from '../components/icons/SearchIcon.jsx'
import { DeleteIcon } from '../components/icons/DeleteIcon.jsx'
import { Receipt, PreviewReceipt } from '../components/pos'
import domtoimage from 'dom-to-image'
import axios from 'axios'

import { loadSettings } from '../actions/auth'

import { connect } from 'react-redux'

import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Checkbox,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner
} from '@nextui-org/react'

const PosPage = ({ settings, loadSettings }) => {
  useEffect(() => {
    loadSettings()
  }, [loadSettings])

  const product_table_columns = [
    {
      key: 'name',
      label: 'ឈ្មោះ'
    },
    {
      key: 'price_usd',
      label: 'តម្លៃ ($)'
    },
    {
      key: 'price_kh',
      label: 'តម្លៃ (៛)'
    }
  ]

  const order_line_columns = [
    // {
    //   key: 'number',
    //   label: 'ល.រ'
    // },
    {
      key: 'description',
      label: 'ឈ្មោះទំនិញ'
    },
    {
      key: 'qty',
      label: 'ចំនួន'
    },
    {
      key: 'unit_price',
      label: 'តម្លៃ'
    },
    {
      key: 'subtotal',
      label: 'សរុប'
    },
    {
      key: 'delete',
      label: ''
    }
  ]

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()

  const [posSession, setPosSession] = useState(null)

  const [currentOrder, setCurrentOrder] = useState(null)

  const [totalCurrentOrder, setTotalCurrentOrder] = useState(0)

  const [totalProductCurrentOrder, setTotalProductCurrentOrder] = useState(0)

  const [products, setProducts] = useState([])

  const [filteredProducts, setFilteredProducts] = useState([])

  const [isAddingItem, setIsAddingItem] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [onModalProduct, setOnModalProduct] = useState(null)

  const [previewReceipt, setPreviewReceipt] = useState(false)

  const [productTableKeyIndicator, setProductTableKeyIndicator] = useState(0)

  const [selectedProduct, setSelectedProduct] = useState(null)

  const getOrder = async () => {
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access')}`,
          Accept: 'application/json'
        }
      }

      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/orders/draft/`,
          config
        )

        let fetchedOrder = res.data.order

        console.log('FETCHED ORDER', fetchedOrder)

        // Get saved order_lines from localStorage
        const savedOrderLines = localStorage.getItem('order_lines')
        if (savedOrderLines) {
          fetchedOrder.order_lines = JSON.parse(savedOrderLines)
        }

        console.log('UPDATED ORDER', fetchedOrder)
        setCurrentOrder(fetchedOrder)
      } catch (error) {
        console.error('Error fetching order:', error)
      }
    }
  }

  useEffect(() => {
    getOrder()
  }, [setCurrentOrder])

  const fetchProducts = async () => {
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access')}`,
          Accept: 'application/json'
        }
      }
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/`,
        config
      )

      setProducts(res.data.products)
      setFilteredProducts(res.data.products)
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [setFilteredProducts])

  const handleSearch = e => {
    const filtered = products.filter(product => {
      return product.name.toLowerCase().includes(e.target.value.toLowerCase())
    })

    if (e.target.value !== '') {
      setFilteredProducts(filtered)
      if (filteredProducts.length !== 0) {
        setProductTableKeyIndicator(filteredProducts[0].id)
        setSelectedProduct(filteredProducts[0])
      } else {
        setProductTableKeyIndicator(0)
        setSelectedProduct(null)
      }
    } else {
      setFilteredProducts(products)
      if (filteredProducts.length !== 0) {
        setProductTableKeyIndicator(filteredProducts[0].id)
        setSelectedProduct(filteredProducts[0])
      } else {
        setProductTableKeyIndicator(0)
        setSelectedProduct(null)
      }
    }
  }

  const [amount, setAmount] = useState('')
  const [price, setPrice] = useState('')
  const [isCustomizedPrice, setIsCustomizedPrice] = useState(false)

  const amountInputRef = useRef(null)
  useEffect(() => {
    if (isAddingItem && amountInputRef.current) {
      amountInputRef.current.focus()
    }
  }, [isAddingItem])

  const priceInputRef = useRef(null)
  useEffect(() => {
    if (isCustomizedPrice && priceInputRef.current) {
      priceInputRef.current.focus()
    }
  }, [isCustomizedPrice])

  // handle add single product
  const handleAddItem = product => {
    if (currentOrder) {
      setOnModalProduct(product)
      setPrice(product.price)
      onOpen()
    }
  }

  const handleDeleteOrder = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access')}`,
        Accept: 'application/json'
      }
    }

    const res = await axios.delete(
      `${process.env.REACT_APP_API_URL}/orders/${currentOrder.id}/`,
      config
    )

    localStorage.removeItem('order_lines')

    getOrder()
  }

  const handleAddToOrder = e => {
    e.preventDefault()
    let updatedOrderLines = [...currentOrder.order_lines]
    const existingLineIndex = updatedOrderLines.findIndex(
      line =>
        line.description === onModalProduct.name &&
        parseFloat(line.unit_price) === parseFloat(price)
    )

    if (existingLineIndex !== -1) {
      console.log('AMOUNT TO ADD', amount)
      updatedOrderLines[existingLineIndex] = {
        ...updatedOrderLines[existingLineIndex], // Keep existing properties
        qty: updatedOrderLines[existingLineIndex].qty + +amount // Update qty
      }
    } else {
      // Add new product to the order
      const newOrderLine = {
        id: `${onModalProduct.name}-${price}`,
        order: currentOrder.id,
        product: onModalProduct.id,
        description: onModalProduct.name,
        qty: +amount,
        unit_price: +price
      }
      updatedOrderLines.push(newOrderLine)
    }

    // Update state properly
    setCurrentOrder(prevOrder => ({
      ...prevOrder, // Keep other properties unchanged
      order_lines: updatedOrderLines // Update order_lines
    }))

    console.log('ORDER_LINES', updatedOrderLines)

    // Use the updated `updatedOrderLines` instead of `currentOrder.order_lines`
    localStorage.setItem('order_lines', JSON.stringify(updatedOrderLines))

    // Clear states
    setAmount('')
    setIsCustomizedPrice(false)
    onClose() // Close modal
  }

  const handleDeleteOrderLine = async order_line => {
    try {
      setCurrentOrder(prevOrder => {
        if (!prevOrder) return prevOrder // Prevent accessing null

        const updatedOrderLines = prevOrder.order_lines.filter(
          line => line.id !== order_line.id
        )

        const updatedOrder = { ...prevOrder, order_lines: updatedOrderLines }

        // Save to localStorage
        localStorage.setItem('order_lines', JSON.stringify(updatedOrderLines))

        return updatedOrder
      })
    } catch (error) {
      console.error(error)
      toast.error('លុបមិនបានសម្រេច')
    }
  }

  const handleIsCustomizedPriceValueChange = e => {
    setIsCustomizedPrice(!isCustomizedPrice)
    if (!e) {
      setPrice(onModalProduct.price)
    }
  }

  const handleCloseModal = () => {
    setAmount('')
    // clear isCustomizedPrice state
    setIsCustomizedPrice(false)
    // close modal
    onClose()
    setIsAddingItem(false)
  }

  const displayPreviewReceipt = () => {
    setPreviewReceipt(true)
  }

  const completeOrder = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access')}`,
        Accept: 'application/json'
      }
    }

    const body = JSON.stringify({
      order: currentOrder.id,
      order_lines: currentOrder.order_lines
    })

    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/orders/complete/`,
      body,
      config
    )

    getOrder()
    setPreviewReceipt(false)
    localStorage.removeItem('order_lines')
  }

  const printReceipt = async () => {
    try {
      window.print()
    } finally {
      toast.success('Printed')
      completeOrder()
      setPreviewReceipt(false)
    }
  }

  const cancelPreviewReceipt = async () => {
    setPreviewReceipt(false)
  }

  const handleKeyDown = event => {
    let keyPressTimeout = null // Store the timeout reference

    if (!isAddingItem && event.key === 'Enter') {
      handleAddItem(selectedProduct)
      setIsAddingItem(true)
    }

    if (isAddingItem && event.key === 'Enter') {
      event.preventDefault()
      handleAddToOrder(event)
      setIsAddingItem(false)
      onClose()
    }

    if (
      !isAddingItem &&
      filteredProducts.length !== 0 &&
      event.key === 'ArrowUp'
    ) {
      let currentIndex = filteredProducts.indexOf(selectedProduct)
      // Calculate the previous index and wrap around using modulo
      let prevIndex =
        (currentIndex - 1 + filteredProducts.length) % filteredProducts.length

      setProductTableKeyIndicator(filteredProducts[prevIndex].id)
      setSelectedProduct(filteredProducts[prevIndex])
    }

    if (
      !isAddingItem &&
      filteredProducts.length !== 0 &&
      event.key === 'ArrowDown'
    ) {
      let currentIndex = filteredProducts.indexOf(selectedProduct)
      let nextIndex = (currentIndex + 1) % filteredProducts.length

      setProductTableKeyIndicator(filteredProducts[nextIndex].id)
      setSelectedProduct(filteredProducts[nextIndex])
    }

    if (event.key === 'Escape') {
      event.preventDefault()
      setIsAddingItem(false)
    }
  }

  const handleButtonClick = event => {
    const target = event.target
    if (target.tagName === 'BUTTON') {
      target.blur()
    }
  }
  useEffect(() => {
    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyDown)

    document.addEventListener('click', handleButtonClick, true)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown, handleButtonClick])

  const productTableContainerRef = useRef(null)

  useEffect(() => {
    // Ensure the selected item is scrolled into view
    const container = productTableContainerRef.current
    const selectedItem = document.querySelector(
      `[data-key="${productTableKeyIndicator}"]`
    )

    if (container && selectedItem) {
      const containerRect = container.getBoundingClientRect()
      const itemRect = selectedItem.getBoundingClientRect()

      // Check if the item is outside the visible area
      if (
        itemRect.top < containerRect.top ||
        itemRect.bottom > containerRect.bottom
      ) {
        // Scroll the container to make the item visible
        container.scrollTop += itemRect.top - containerRect.top
      }
    }
  }, [productTableKeyIndicator])

  const renderProductCell = React.useCallback(
    (product, columnKey, settings) => {
      const cellValue = product[columnKey]

      switch (columnKey) {
        case 'price_usd':
          return `${(product.price / settings.exchange_rate).toFixed(2)} $`
        case 'price_kh':
          return `${product.price.toLocaleString()} ៛`
        default:
          return cellValue
      }
    },
    []
  )

  const renderOrderLineCell = React.useCallback(
    (orderLine, columnKey, settings, order_lines, currentOrder) => {
      console.log('currentOrder', currentOrder)
      const cellValue = orderLine[columnKey]
      switch (columnKey) {
        // case 'number':
        //   return order_lines.indexOf(orderLine) + 1
        case 'subtotal':
          return `${(orderLine.qty * orderLine.unit_price).toLocaleString()} ៛`
        case 'qty':
          return orderLine.qty
        case 'unit_price':
          return `${orderLine.unit_price.toLocaleString()} ៛`
        case 'description':
          return orderLine.description
        case 'delete':
          return (
            <>
              <span
                onClick={e => handleDeleteOrderLine(orderLine)}
                color='danger'
              >
                <span className='text-lg cursor-pointer text-danger active:opacity-50'>
                  <DeleteIcon />
                </span>
              </span>
            </>
          )
        default:
          return cellValue
      }
    },
    [currentOrder]
  )

  useEffect(() => {
    let total = currentOrder?.order_lines.reduce((sum, line) => {
      return sum + line.qty * line.unit_price
    }, 0)

    let uniqueItemsCount = new Set(
      currentOrder?.order_lines.map(line => line.description)
    ).size

    setTotalCurrentOrder(total)
    setTotalProductCurrentOrder(uniqueItemsCount)
  }, [currentOrder, setCurrentOrder])

  return (
    <div className='flex w-full h-full pos__container'>
      <div className='hidden print-only'>
        {currentOrder && settings && (
          <Receipt order={currentOrder} settings={settings} />
        )}
      </div>
      {isLoading ? (
        <div className='flex items-center justify-center w-full h-full'>
          <Spinner />
        </div>
      ) : (
        <>
          <Modal
            isOpen={isOpen}
            size='md'
            onOpenChange={onOpenChange}
            scrollBehavior='inside'
            className='w-[auto]'
          >
            <ModalContent>
              {onClose => (
                <>
                  <ModalHeader className='flex flex-col gap-1'>
                    បញ្ចូល ({onModalProduct.name}) ទៅការបញ្ជាទិញ
                  </ModalHeader>
                  <form onSubmit={handleAddToOrder}>
                    <ModalBody>
                      <Input
                        type='number'
                        label={`បរិមាណ`}
                        name='quantity'
                        placeholder='Enter order quantity'
                        labelPlacement='outside'
                        value={amount}
                        onValueChange={setAmount}
                        ref={amountInputRef}
                      />
                      <Checkbox
                        isSelected={isCustomizedPrice}
                        onValueChange={handleIsCustomizedPriceValueChange}
                      >
                        ផ្លាស់ប្តូរតម្លៃ?
                      </Checkbox>
                      <Input
                        type='number'
                        label='តម្លៃ'
                        placeholder='0.00'
                        labelPlacement='outside'
                        disabled={!isCustomizedPrice}
                        ref={priceInputRef}
                        value={price}
                        onValueChange={setPrice}
                        endContent={
                          <div className='flex items-center pointer-events-none'>
                            <span className='text-default-400 text-small'>
                              ៛
                            </span>
                          </div>
                        }
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color='danger'
                        variant='light'
                        onPress={handleCloseModal}
                      >
                        បោះបង់
                      </Button>
                      <Button
                        type='submit'
                        color='primary'
                        isDisabled={+amount <= 0}
                      >
                        បញ្ចូល
                      </Button>
                    </ModalFooter>
                  </form>
                </>
              )}
            </ModalContent>
          </Modal>

          {!previewReceipt ? (
            <div className='no-print container mx-auto pos_contents w-[60%] h-[calc(100%-70px)] px-10 py-5 flex flex-col gap-4'>
              <section className='w-full py-[15px]'>
                <Input
                  classNames={{
                    base: 'w-full h-10',
                    mainWrapper: 'h-full',
                    input: 'text-small',
                    inputWrapper:
                      'h-full font-normal text-default-500 bg-default-400/20 dark:bg-default-500/20'
                  }}
                  placeholder='ស្វែងរក...'
                  size='lg'
                  startContent={<SearchIcon size={18} />}
                  type='search'
                  onChange={e => handleSearch(e)}
                />
              </section>

              <div className='overflow-y-scroll' ref={productTableContainerRef}>
                {settings && (
                  <Table
                    removeWrapper
                    color='success'
                    selectionMode='single'
                    selectedKeys={[productTableKeyIndicator]}
                    aria-label='Product Table'
                  >
                    <TableHeader columns={product_table_columns}>
                      {column => (
                        <TableColumn key={column.key}>
                          {column.label}
                        </TableColumn>
                      )}
                    </TableHeader>
                    <TableBody
                      items={filteredProducts}
                      emptyContent='គ្មានទំនិញសម្រាប់បង្ហាញ'
                      isLoading={isLoading}
                      loadingContent={<Spinner label='Loading...' />}
                    >
                      {item => (
                        <TableRow key={item.key}>
                          {columnKey => (
                            <TableCell>
                              {renderProductCell(item, columnKey, settings)}
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </div>
            </div>
          ) : previewReceipt ? (
            <div className='no-print w-[60%] h-[calc(100%-70px)] container mx-auto overflow-y-scroll flex flex-col justify-start gap-2 items-center bg-gray-400 py-5 px-10'>
              {currentOrder && settings && (
                <PreviewReceipt order={currentOrder} settings={settings} />
              )}
              <div className='mt-2 flex flex-row w-[320px] gap-2'>
                <button
                  onClick={() => printReceipt()}
                  className='w-full px-4 py-2 text-white bg-green-500 rounded hover:bg-green-700'
                >
                  បោះពុម្ព
                </button>
                <button
                  onClick={() => cancelPreviewReceipt()}
                  className='w-full px-4 py-2 text-white bg-red-500 rounded hover:bg-red-700'
                >
                  បោះបង់
                </button>
              </div>
            </div>
          ) : (
            <div className='flex justify-center items-center w-[40%] h-[100%] px-10 py-5'>
              សូមជ្រើសរើស ឬបង្កើតការបញ្ជាទិញ
            </div>
          )}
          <div className='no-print pos w-[40%] h-[calc(100%-70px)] flex flex-col justify-between bg-[#f5f5f5] px-5 pt-10 pb-5'>
            <div className='flex flex-col h-full gap-10'>
              {settings && currentOrder && (
                <div className='flex flex-col justify-between h-full gap-3'>
                  <section className='flex flex-col gap-4'>
                    <h1 className='text-xl text-center'>
                      ការបញ្ជាទិញ {currentOrder ? currentOrder.number : ''}
                    </h1>

                    <hr className='dash' />
                    <div className='overflow-y-scroll max-h-[500px]'>
                      <Table
                        isHeaderSticky
                        removeWrapper
                        isStriped
                        color='success'
                        aria-label='Example static collection table'
                      >
                        <TableHeader
                          className='bg-white'
                          columns={order_line_columns}
                        >
                          {column => (
                            <TableColumn key={column.key}>
                              {column.label}
                            </TableColumn>
                          )}
                        </TableHeader>
                        <TableBody
                          items={currentOrder.order_lines}
                          emptyContent='បញ្ចូលទំនិញ'
                          isLoading={isLoading}
                          loadingContent={<Spinner label='' />}
                        >
                          {item => (
                            <TableRow
                              key={`${item.description}-${item.unit_price}`}
                            >
                              {columnKey => (
                                <TableCell>
                                  {renderOrderLineCell(
                                    item,
                                    columnKey,
                                    settings,
                                    currentOrder.order_lines,
                                    currentOrder
                                  )}
                                </TableCell>
                              )}
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  </section>
                  <section className='flex flex-col gap-2 mt-auto text-lg font-semibold'>
                    <div className='flex justify-between'>
                      <span>មុខទំនិញសរុប</span>
                      <span>{totalProductCurrentOrder}</span>
                    </div>
                    <hr className='dash' />
                    <div className='flex justify-between'>
                      <span>សរុប (៛)</span>
                      <span>{totalCurrentOrder} ៛</span>
                    </div>
                  </section>

                  <hr className='dash' />
                </div>
              )}

              {!currentOrder && (
                <div className='flex items-center justify-center w-full h-full'>
                  <p>សូមជ្រើសរើស ឬបង្កើតការបញ្ជាទិញ</p>
                </div>
              )}
            </div>

            {currentOrder && (
              <section className='flex flex-col gap-2 pt-2'>
                <div className='flex flex-row w-full gap-4'>
                  <Button
                    size='lg'
                    color='secondary'
                    onClick={() => displayPreviewReceipt()}
                    className={`w-full text-white font-bold`}
                  >
                    បោះពុម្ពនិងរក្សាទុក
                  </Button>
                  <Button
                    size='lg'
                    color='success'
                    onClick={() => completeOrder()}
                    className={`w-full text-white font-bold`}
                  >
                    រក្សាទុក
                  </Button>
                </div>
                <div className='w-full'>
                  <Button
                    size='lg'
                    color='danger'
                    onClick={e => handleDeleteOrder(currentOrder.id)}
                    className={`w-full text-white font-bold`}
                  >
                    លុប
                  </Button>
                </div>
              </section>
            )}
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  settings: state.auth.settings
})

export default connect(mapStateToProps, { loadSettings })(PosPage)
