import React from 'react'

const PreviewReceipt = ({ order, settings }) => {
  const date = new Date(order.created_at)

  const formattedTime = date.toLocaleTimeString('kh', {
    hour: '2-digit',
    minute: '2-digit'
  })

  let count = 0

  let total = order.order_lines.reduce((sum, line) => {
    return sum + line.qty * line.unit_price;
  }, 0);  

  const getChars = (s, n) => s.substring(s.length - n)

  return (
    <div className='flex flex-col w-full h-auto p-1 bg-white no-print'>
      <h1 className='pb-1 text-3xl font-bold text-center'>
        {settings.shop_name}
      </h1>
      <p className='pb-1 text-base text-center'>
        {`ថ្ងៃទី${date.getDate()} ខែ${
          date.getMonth() + 1
        } ឆ្នាំ${date.getFullYear()}`}
      </p>
      <div className='flex flex-col justify-start w-full gap-1 pb-2'>
        <section className='flex flex-row justify-between text-base'>
          <div className='pb-1 text-left text-nowrap'>
            <span className='font-bold'>អត្រាប្ដូរប្រាក់:</span>{' '}
            {settings.exchange_rate.toLocaleString()}៛
          </div>
          <div className='pb-1 text-left text-nowrap'>
            <span className='font-bold'>ម៉ោង:</span> {formattedTime.slice(0, 5)}{' '}
            {getChars(formattedTime, 2) == 'AM' ? 'ព្រឹក' : 'ល្ងាច'}
          </div>
        </section>
      </div>
      <hr className='mb-2 border-black border-dashed' />
      <div className='w-full font-sans'>
        <table className='w-full h-auto text-base bg-white'>
          <thead className='bg-gray-100'>
            <tr className='text-white bg-black'>
              <th className='py-2 font-semibold text-center'>ល.រ</th>
              <th className='p-2 font-semibold text-center'>ទំនិញ</th>
              <th className='p-2 font-semibold text-center'>ចំនួន</th>
              <th className='p-2 font-semibold text-center'>តម្លៃ</th>
              <th className='p-2 font-semibold text-center'>សរុប</th>
            </tr>
          </thead>

          <tbody>
            {order.order_lines.map((order_line, idx) => {
              count += 1
              return (
                <tr key={order_line.id}>
                  <td className='py-2 text-center'>{count}</td>
                  <td>{order_line.description}</td>
                  <td className='p-1 text-center'>{order_line.qty}</td>
                  <td className='p-1 text-center text-nowrap'>
                    {order_line.unit_price.toLocaleString()} ៛
                  </td>
                  <td className='p-2 text-center text-nowrap'>
                    {(order_line.qty * order_line.unit_price).toLocaleString()} ៛
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <hr className='mt-2 border-black border-dashed' />
      <div className='flex flex-col items-end justify-end pt-2'>
        <p className='flex flex-row justify-between w-full text-xl font-bold text-nowrap'>
          <span>សរុប​ (៛)</span>
          <span className='font-bold'>{total.toLocaleString()} ៛</span>
        </p>
      </div>
    </div>
  )
}

export default PreviewReceipt
